/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import MessageStatusComponent from "../components/Dashboard/MessageStatus";
import ProductTypeStatsComponent from "../components/Dashboard/ProductTypeStats";
import MessageStatusMonthlyComponent from "../components/Dashboard/MessageStatusMonthly";

function Dashboard(props) {


  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Message Stats</h5>
                  </Col>

                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <MessageStatusMonthlyComponent />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Message Stats</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <MessageStatusComponent />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Product Stats</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <ProductTypeStatsComponent />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
