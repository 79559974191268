
import React, { useState, useEffect } from 'react';
import NotificationAlert from "react-notification-alert";
import { getItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col,
    CardSubtitle,
    CardImg,
    Input,
    FormGroup,
    Form
} from "reactstrap";

import { Button } from '@aws-amplify/ui-react';
import { Blocks } from 'react-loader-spinner';

function BestSellers() {
    const [IsLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([])
    const [all, setAll] = useState([])
    const [showAdd, setShowAdd] = useState(false)
    const [product, setProduct] = useState([])
    const [token, setToken] = useState([])

    const notificationAlertRef = React.useRef(null);
    const notify = (place, notifyMessage, type) => {
        var options = {};
        options = {
            place: place,
            message: (
                notifyMessage
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        const getProducts = async () => {
            setToken((await Auth.currentSession()).getIdToken().getJwtToken())
            getItem('/products/bestSelling', token).then(resp => {
                setProducts(resp.data)
                setIsLoaded(true)
            }).catch(resp => {
                setProducts([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
        }
        getProducts()
    }, [])

    const removeProductById = (productIdToRemove) => {
        const updatedProducts = products.filter(item => item.productId !== productIdToRemove);
        setProducts(updatedProducts);
    };

    const updateList = (item, remove) => {
        console.log(remove)
        getItem(`/admin/products/bestSelling/${!remove ? `${product.productId}` : item.productId}`, token).then(resp => {
            let notifyMessage = (<div>
                <div>
                    Best Sellers updated.
                </div>
            </div>)
            notify("tl", notifyMessage, "success")
            if (remove) {
                removeProductById(item.productId);
            } else {
                console.log("Adding new", product)
                setProducts(prevProducts => [...prevProducts, resp.data]);
            }
            setIsLoaded(true)
        }).catch(resp => {
            setIsLoaded(true)
            let notifyMessage = (<div>
                <div>
                    Error updating the list.
                </div>
            </div>)
            notify("tl", notifyMessage, "danger")
        })
    }

    const show = () => {
        if (products.length === 5) {
            setIsLoaded(true)
            let notifyMessage = (<div>
                <div>
                    Best Selling list is full. Please remove one product to add another.
                </div>
            </div>)
            notify("tl", notifyMessage, "danger")
        } else {
            getItem(`/products/filtered`, token).then(resp => {
                setIsLoaded(true)
                setAll(resp.data)
                setShowAdd(true)
            }).catch(resp => {
                setIsLoaded(true)
                let notifyMessage = (<div>
                    <div>
                        Error retreiving products.
                    </div>
                </div>)
                notify("tl", notifyMessage, "danger")
            })
        }
    }

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            {IsLoaded ? (
                <div className="content">
                    <Row>
                        <Col md="6">
                            <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
                                <CardHeader style={{ marginBottom: "15px" }}>
                                    <Row>
                                        <Col md="10">
                                            <CardTitle tag="h4">Best Sellers</CardTitle>
                                            <p className="category">The five best selling products out of the Novas catalogue.</p>
                                        </Col>
                                        <Col><Button style={{ color: "white" }} onClick={show}>
                                            <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                        </Button></Col></Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    {showAdd ?
                        <Row>
                            <Col md="6">
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <FormGroup>
                                                <label>
                                                    Product
                                                </label>
                                                <Input
                                                    id="productSelect"
                                                    name="product"
                                                    type="select"
                                                    value={product.productId}
                                                    onChange={(e) => {
                                                        const selectedProduct = all.find(item => item.productId === e.target.value);
                                                        setProduct(selectedProduct);
                                                    }}
                                                >
                                                    {all.map((item) => (
                                                        <option key={item.productId} value={item.productId}>
                                                            {item.productName}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                    <CardFooter>
                                        <Button className="btn-fill" color="primary" type="submit" onClick={(e) => {updateList(undefined, false)}}>
                                            Save
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row> : <></>
                    }

                    <Row>
                        {products.map((item) => {
                            return (
                                <Col md="4" key={item.productId}>
                                    <Card className='card-plain'>
                                        <CardBody className='text-right'>
                                            <Button className="btn-icon btn-round" style={{ margin: '1rem' }} onClick={(e) => updateList(item, true)}>
                                                <i className="tim-icons icon-simple-remove" style={{ color: "red" }} />
                                            </Button>
                                        </CardBody>

                                        <CardBody style={{ verticalAlign: "center" }}>
                                            <CardTitle tag="h4">
                                                {item.productName}
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                            >
                                                {item.productTypeId}
                                            </CardSubtitle>
                                        </CardBody>
                                        <CardBody>
                                            <CardImg alt={`${item.productName}`} src={`${process.env.REACT_APP_NOVAS_IMAGE_BASE}${item.productUrlSmall}`} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            ) : (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                    /></div>

            )
            }
        </>
    )
}

export default BestSellers;