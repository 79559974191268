import React, { useState, useEffect } from 'react';
import { getItem, getFileItem, deleteItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";

import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import ProductManager from './ProductManager';

function Product(props) {
    const [IsLoaded, setIsLoaded] = useState(false);
    const [product, setProduct] = useState([])
    const [showUpdate, setShowUpdate] = useState(false);
    const [token, setToken] = useState([])
    const notificationAlertRef = React.useRef(null);
    const history = useHistory();

    const notify = (place, message, type) => {
        //  type = "primary";
        // type = "success";
        // type = "danger";
        // type = "warning";
        // type = "info";

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        const getProduct = async () => {
            const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
            getItem(`/admin/products/${props.location.state.productId}`, token).then(resp => {
                setProduct(resp.data)
                setIsLoaded(true)

            }).catch(resp => {
                setProduct([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
        }
        getProduct()
    }, [])

    const toggleShowUpdate = () => {
        setShowUpdate(showUpdate ? false : true)
    }

    const deleteProduct = async () => {
        const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
        deleteItem(`/admin/products/${props.location.state.productId}`, token).then(resp => {
            let state = "success"
            // notify("tl", message, "success")

            history.push("/products", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"
            // notify("tl", message, "success")

            history.push("/products", state);
        })
        //stop loading when data is fetched
    }

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            {IsLoaded ?
                <div className="content">
                    <Row>
                        <Col>
                            <Card className="card-plain">
                                <CardHeader><Row><Col><h2 className="title" style={{ textAlign: "center" }}>{product.productName}</h2></Col>

                                    <Col><div className="button-container">
                                        <Button className="btn-icon btn-round" onClick={toggleShowUpdate}>
                                            <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                        </Button>&nbsp;&nbsp;&nbsp;
                                        <Button className="btn-icon btn-round" onClick={deleteProduct}>
                                            <i className="tim-icons icon-trash-simple" style={{ color: "red" }} />
                                        </Button>
                                    </div></Col>
                                </Row>
                                </CardHeader>
                                <CardBody>
                                    {showUpdate &&
                                        <Row>
                                            <Card>
                                                <ProductManager value={product} />
                                            </Card>
                                        </Row>
                                    }
                                    <Row>
                                        {/* <CardText /> */}
                                        <Col>
                                            <div className="card-description">
                                                <img
                                                    alt={`${product.productName}`}
                                                    // className="avatar"
                                                    src={`${process.env.REACT_APP_NOVAS_IMAGE_BASE}${product.productUrl}`}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardHeader><h4>Product Details</h4></CardHeader>
                                                <CardBody>
                                                    <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>
                                                        {product.visible ? 'Product visible' : 'Product hidden'}
                                                    </p>
                                                    <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>{product.description}</p>
                                                    <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>{product.specs}</p>

                                                    {product.benefits.map((benefit) => {
                                                        return (
                                                            <div style={{ marginTop: "5px", marginLeft: "5px" }} key={benefit}>{benefit}</div>
                                                        );
                                                    })}

                                                    {product.sizes.map((size) => {
                                                        return (
                                                            <div style={{ color: "#747FA7", marginTop: "10px", marginLeft: "7px" }} key={size}>{size}</div>
                                                        );
                                                    })}

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>


                    </Row>


                </div>
                // {/* } */}
                : <></>}
        </>
    );
}

export default Product;