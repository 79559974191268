import Dashboard from "./views/Dashboard.js";
import ProductTypes from "./views/ProductTypes";
import ProductType from "./views/ProductType";
import Products from "./views/Products";
import Product from "./views/Product";
import ProductTypeManager from "./views/ProductTypeManager";
import ProductManager from "./views/ProductManager";
import Messages from "./views/Messages";
import Message from "./views/Message";
import BestSellers from "./views/BestSellers";
import Leads from "./views/Leads.js";
import Stock from "./views/Stock.js";
// import Users from "./views/Users.js";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    show: true
  },
  {
    path: "/product-types",
    name: "Product Types",
    rtlName: "Product Types",
    icon: "tim-icons icon-molecule-40",
    component: ProductTypes,
    layout: "/admin",
    show: true
  },{
    path: "/product-type/:id",
    name: "Product Type",
    rtlName: "Product Type",
    icon: "tim-icons icon-badge",
    component: ProductType,
    layout: "/admin",
    show: false
  },{
    path: "/products",
    name: "Products",
    rtlName: "Products",
    icon: "tim-icons icon-atom",
    component: Products,
    layout: "/admin",
    show: true
  },{
    path: "/product",
    name: "Product",
    rtlName: "Product",
    icon: "tim-icons icon-badge",
    component: Product,
    layout: "/admin",
    show: false
  },{
    path: "/product-type-manager",
    name: "Product Type Manager",
    rtlName: "Product Type Manager",
    icon: "tim-icons icon-badge",
    component: ProductTypeManager,
    layout: "/admin",
    show: false
  },{
    path: "/product-manager",
    name: "Product Manager",
    rtlName: "Product Manager",
    icon: "tim-icons icon-badge",
    component: ProductManager,
    layout: "/admin",
    show: false
  },{
    path: "/best-sellers",
    name: "Best Sellers",
    rtlName: "Best Sellers",
    icon: "tim-icons icon-coins",
    component: BestSellers,
    layout: "/admin",
    show: true
  },{
    path: "/messages",
    name: "Messages",
    rtlName: "Messages",
    icon: "tim-icons icon-email-85",
    component: Messages,
    layout: "/admin",
    show: true
  },{
    path: "/message",
    name: "Message",
    rtlName: "Message",
    icon: "tim-icons icon-email-85",
    component: Message,
    layout: "/admin",
    show: false
  },
  {
    divider: true,
    show: true
  },
    {
    path: "/leads",
    name: "Leads",
    rtlName: "Message",
    icon: "tim-icons icon-email-85",
    component: Leads,
    layout: "/admin",
    show: true
  },
  ,{
    path: "/stock",
    name: "Stock",
    rtlName: "Message",
    icon: "tim-icons icon-email-85",
    component: Stock,
    layout: "/admin",
    show: true
  },

];
export default routes;
