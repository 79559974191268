import React, { useState, useEffect, useContext } from 'react';
import { getItem, getFileItem, deleteItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { DataGrid } from '@mui/x-data-grid';

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    Row,
    Col,
    Input
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import ProductTypeManager from './ProductTypeManager';
import { useHistory } from "react-router-dom";
import { ThemeContext, themes } from '../contexts/ThemeContext';
import { useParams } from 'react-router-dom';

function ProductType(props) {

    const { theme } = useContext(ThemeContext);

    const { id } = useParams(); 

    const [textColour, setColour] = useState("white")
    const [IsLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([])
    const [filtered, setFiltered] = useState([])
    const [filter, setFilter] = useState(false)
    const [showUpdate, setShowUpdate] = useState(false);
    const [token, setToken] = useState([])
    const [productType, setProductType] = useState({})
    // const [messagesState, setMessages] = useState([])
    const notificationAlertRef = React.useRef(null);
    const history = useHistory();
  
    const notify = (place, message, type) => {

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        let products = [];
        let message = (
          <div>
            <div>
              Error retrieved getting the data
            </div>
          </div>
        );
      
        console.log("Params are", id);
      
        const getProducts = async () => {
          setColour(document.body.classList.contains("white-content") ? "black" : "white");
          const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
          if (props.location.state !== null && props.location.state?.productTypeId) {
            console.log("Not null")
            console.log(props.location.state)
            setProductType(props.location.state);
            try {
            console.log(token)
              getItem(`/admin/products/productTypeName/${props.location.state.productTypeId}`, token).then(resp => {

                console.log("Heyo",resp.data)
                console.log(resp)
                setProducts(resp.data);
              }).catch(err => {
                console.log("Error", err)
              })
             
            } catch (error) {
              setProducts([]);
              setIsLoaded(true);
              notify("tl", message, "danger");
            }
          } else {
            console.log("null")
            try {
              const productTypeResp = await getItem(`/product_types/${id}`, token);
              console.log(productTypeResp)
              setProductType(productTypeResp.data);
              
              const resp = await getItem(`/products/${id}`, token);
              products = resp.data;
              setProducts(products);
            } catch (error) {
              setProductType({});
              setProducts([]);
              setIsLoaded(true);
              notify("tl", message, "danger");
            }
          }
          // Stop loading when data is fetched
        };
      
        getProducts();
      }, []);

    //on Search Data in Table 
    const onSearchData = (e) => {
        var searchData = [];
        if (e.target.value !== "") {
            function find(arr) {
                var result = [];
                for (var i in arr) {
                    if (arr[i].productName.toLowerCase().match(e.target.value.toLowerCase())) {
                        result.push(arr[i]);
                    }
                }
                return result;
            }
            searchData = find(products);
            setFiltered(searchData)
            setFilter(true)
        } else {
            searchData = [];
            setFilter(searchData)
            setFilter(false)
        }
    }

    const toggleShowUpdate = () => {
        setShowUpdate(showUpdate ? false : true)
    }

    const deleteProductType = async () => {
        const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
        deleteItem(`/product_types/${productType.productTypeId}`, token).then(resp => {
            let state = "success"
            // notify("tl", message, "success")

            history.push("/product-types", state);

        }).catch(resp => {
            setProducts([])
            setIsLoaded(true)
            let state = "danger"
            // notify("tl", message, "success")

            history.push("/products", state);
        })
        //stop loading when data is fetched
    }

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            <div className="content">
                <Row>
                    <Col md="5">
                        <Card className="card-plain">
                            <CardHeader><Row><Col md="8"><h2 className="title">{productType.productTypeName}</h2></Col>

                                <Col>
                                    <div className="button-container">
                                        <Button className="btn-icon btn-round" onClick={toggleShowUpdate}>
                                            <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button className="btn-icon btn-round" onClick={deleteProductType}>
                                            <i className="tim-icons icon-trash-simple" style={{ color: "red" }} />
                                        </Button>
                                    </div>
                                </Col></Row>
                            </CardHeader>
                            <CardBody>
                                {/* <CardText /> */}
                                <div className="card-description">
                                    {/* <div className="block block-one" />
                                    <div className="block block-two" />
                                    <div className="block block-three" />
                                    <div className="block block-four" /> */}

                                    <img
                                        alt={`${productType.productTypeName}`}
                                        // className="avatar"
                                        src={`${process.env.REACT_APP_NOVAS_IMAGE_BASE}${productType.productTypeUrl}`}
                                    />
                                    {/* {productType.profession}
                                    <p className="description">{productType.emailAddress} {productType.phoneNumber}</p> */}
                                </div>

                            </CardBody>
                        </Card>
                        {showUpdate ? <Card>
                            <ProductTypeManager value={productType} id={productType.productTypeId} />
                        </Card> : <></>}
                    </Col>
                    <Col>

                        <Row>

                        </Row>
                        <Row>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Products</CardTitle>
                                    <CardText>Products available under the {productType.productTypeName} range</CardText>
                                </CardHeader>
                                <CardBody>

                                    <div>
                                        <Input
                                            placeholder="Search by product name..."
                                            onChange={(e) => onSearchData(e)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Row>
                        <Row>
                            <Card className='card-plain'>
                                <DataGrid
                                    rows={filter ? filtered : products}
                                    sx={{
                                        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                                          color: theme === themes.dark ? 'white' : 'black',
                                         fontWeight: 700,
                                      },
                                    }}    
                                    columns={[
                                        { field: 'productName', headerName: 'Name', flex: 1 },
                                        { field: 'description', headerName: 'Description', flex: 1 },
                                        {
                                            field: 'actions',
                                            headerName: 'Action',
                                            flex: 1,
                                            renderCell: (params) => (
                                                <div className="button-container">
                                                    <Link to={{
                                                        pathname: `/product/${params.row.productId}`,
                                                        state: params.row
                                                    }}>
                                                        <Button>
                                                            <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                        </Button>
                                                    </Link>
                                                </div>
                                            ),
                                        },
                                    ]}
                                    getRowId={(row) => row.productId}
                                />
                            </Card></Row>
                    </Col>
                </Row>

                {/* <Row>
                    <Card>
                        <CardBody><div className="button-container"><span style={{paddingRight: "2em"}}>Conversation on {startDate} from {startTime} to {endTime}</span>
                            
                                <Button className="btn-icon btn-round" onClick={onConversationClick}>
                                    <i className="tim-icons icon-cloud-download-93" />
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Row>

                {listItems} */}
            </div>
            {/* } */}
        </>
    );
}

export default ProductType;