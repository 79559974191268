import logo from "./assets/img/react-logo.png";

export let AmplifyComponent = {
  Header() {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <img
          src={logo} // Replace 'your_image_url.jpg' with the URL/path of your image
          alt="Centered Image"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>
    );
  },

  Footer() {
    return (
      <>
        <br />
        <div style={{ width: "100%", bottom: "0px" }}></div>
      </>
    );
  },
};
