import React, { useState, useEffect, useContext } from 'react';
import NotificationAlert from "react-notification-alert";
import { getItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Blocks } from 'react-loader-spinner';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeContext, themes } from '../contexts/ThemeContext';

function Messages() {

    const { theme } = useContext(ThemeContext);

    const [textColour, setColour] = useState("white")
    const [IsLoaded, setIsLoaded] = useState(false);
    const [unseenMessages, setUnseenMessages] = useState([])
    const [unprocessedMessages, setUnprocessedMessages] = useState([])
    const [processedMessages, setProcessedMessages] = useState([])

    const notificationAlertRef = React.useRef(null);
    const notify = (place, message, type) => {
        //  type = "primary";
        // type = "success";
        // type = "danger";
        // type = "warning";
        // type = "info";

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    // const onSearchData = (e) => {
    //     var searchData = [];
    //     if (e.target.value !== "") {
    //       function find(arr) {
    //         var result = [];
    //         for (var i in arr) {
    //           if (arr[i].productName.toLowerCase().match(e.target.value.toLowerCase()) ||
    //             arr[i].productType.productTypeName.toLowerCase().match(e.target.value.toLowerCase())) {
    //             result.push(arr[i]);
    //           }
    //         }
    //         console.log(result)
    //         return result;
    //       }
    //       searchData = find(products);
    //       setFiltered(searchData)
    //       setFilter(true)
    //     } else {
    //       searchData = [];
    //       setFilter(searchData)
    //       setFilter(false)
    //     }
    //   }

    useEffect(() => {

        const getMessages = async () => {
            setColour(document.body.classList.contains("white-content") ? "black" : "white");
            const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
            getItem('/messages/unseen', token).then(resp => {
                setUnseenMessages(resp.data)
                setIsLoaded(true)
            }).catch(resp => {
                setUnseenMessages([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
            getItem('/messages/seenAndUncompleted', token).then(resp => {
                setUnprocessedMessages(resp.data)
                setIsLoaded(true)
            }).catch(resp => {
                setUnprocessedMessages([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
            getItem('/messages/completed', token).then(resp => {
                setProcessedMessages(resp.data)
                setIsLoaded(true)
            }).catch(resp => {
                setProcessedMessages([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
            //stop loading when data is fetched
        }
        getMessages()

    }, [])

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            {IsLoaded ? (
                <div className='content'>
                    <Accordion style={{
                        backgroundColor: 'transparent',
                        color: theme === themes.dark ? 'white' : 'black',
                        borderColor: theme === themes.dark ? 'white' : 'black',
                        borderStyle: 'solid', // Specify border style
                        borderWidth: '2px',
                    }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: theme === themes.dark ? 'white' : 'black', }} />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Unseen Messages</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Row>
                                <Card className='card-plain'>

                                    <DataGrid
                                        rows={unseenMessages}
                                        getRowId={(row) => row.messageId}
                                        columns={[
                                            { field: 'customerName', headerName: 'Name', flex: 1 },
                                            { field: 'topic', headerName: 'Topic', flex: 1 },
                                            { field: 'timestamp', headerName: 'Timestamp', flex: 1 },
                                            {
                                                field: 'action',
                                                headerName: 'Action',
                                                flex: 1,
                                                renderCell: (params) => (
                                                    <div className="button-container">
                                                        <Link
                                                            to={{
                                                                pathname: '/message',
                                                                state: params.row // Assuming 'item' is passed as 'params.row'
                                                            }}
                                                        >
                                                            <Button style={{ color: `${textColour}` }}>
                                                                <i className="tim-icons icon-zoom-split" style={{ color: 'rgba(75, 192, 192, 1)' }} />
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        ]}
                                        sx={{
                                            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                                                color: theme === themes.dark ? 'white' : 'black',
                                                fontWeight: 700,
                                            },
                                        }}
                                        autoHeight
                                        disableSelectionOnClick
                                    />
                                </Card>
                            </Row>
                        </AccordionDetails>
                    </Accordion>
                    <br />
                    <Accordion style={{
                        backgroundColor: 'transparent',
                        color: theme === themes.dark ? 'white' : 'black',
                        borderColor: theme === themes.dark ? 'white' : 'black',
                        borderStyle: 'solid', // Specify border style
                        borderWidth: '2px',
                    }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: theme === themes.dark ? 'white' : 'black', }} />} aria-controls="panel2a-content" id="panel2a-header">
                            <Typography>Unprocessed Messages</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Row>
                                <Card className='card-plain'>

                                    <DataGrid
                                        rows={unprocessedMessages}
                                        getRowId={(row) => row.messageId}
                                        columns={[
                                            { field: 'customerName', headerName: 'Name', flex: 1 },
                                            { field: 'topic', headerName: 'Topic', flex: 1 },
                                            { field: 'timestamp', headerName: 'Timestamp', flex: 1 },
                                            {
                                                field: 'action',
                                                headerName: 'Action',
                                                flex: 1,
                                                renderCell: (params) => (
                                                    <div className="button-container">
                                                        <Link
                                                            to={{
                                                                pathname: '/message',
                                                                state: params.row // Assuming 'item' is passed as 'params.row'
                                                            }}
                                                        >
                                                            <Button style={{ color: `${textColour}` }}>
                                                                <i className="tim-icons icon-zoom-split" style={{ color: 'rgba(75, 192, 192, 1)' }} />
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        ]}
                                        sx={{
                                            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                                                color: theme === themes.dark ? 'white' : 'black',
                                                fontWeight: 700,
                                            },
                                        }}
                                        autoHeight
                                        disableSelectionOnClick
                                    />

                                </Card>
                            </Row>
                        </AccordionDetails>
                    </Accordion>
                    <br />
                    <Accordion style={{
                        backgroundColor: 'transparent',
                        color: theme === themes.dark ? 'white' : 'black',
                        borderColor: theme === themes.dark ? 'white' : 'black',
                        borderStyle: 'solid', // Specify border style
                        borderWidth: '2px',
                    }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: theme === themes.dark ? 'white' : 'black', }} />} aria-controls="panel3a-content" id="panel3a-header">
                            <Typography>Processed Messages</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Row>
                                <Card className='card-plain'>
                                    <DataGrid
                                        rows={processedMessages}
                                        getRowId={(row) => row.messageId}
                                        columns={[
                                            { field: 'customerName', headerName: 'Name', flex: 1 },
                                            { field: 'topic', headerName: 'Topic', flex: 1 },
                                            { field: 'timestamp', headerName: 'Timestamp', flex: 1 },
                                            {
                                                field: 'action',
                                                headerName: 'Action',
                                                flex: 1,
                                                renderCell: (params) => (
                                                    <div className="button-container">
                                                        <Link
                                                            to={{
                                                                pathname: '/message',
                                                                state: params.row // Assuming 'item' is passed as 'params.row'
                                                            }}
                                                        >
                                                            <Button style={{ color: `${textColour}` }}>
                                                                <i className="tim-icons icon-zoom-split" style={{ color: 'rgba(75, 192, 192, 1)' }} />
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        ]}
                                        sx={{
                                            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                                                color: theme === themes.dark ? 'white' : 'black',
                                                fontWeight: 700,
                                            },
                                        }}
                                        autoHeight
                                        disableSelectionOnClick
                                    />

                                </Card>
                            </Row>
                        </AccordionDetails>
                    </Accordion>

                </div>
            ) : (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                    /></div>

            )
            }
        </>
    )

}

export default Messages;