import React, { useState, useEffect, useContext } from 'react';
import { getItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { DataGrid } from '@mui/x-data-grid';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Blocks } from 'react-loader-spinner';
import { themes } from '../contexts/ThemeContext';
import { ThemeContext } from '../contexts/ThemeContext';
function Products(props) {

  const { theme } = useContext(ThemeContext);

  const [textColour, setColour] = useState("white")
  const [IsLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState([])
  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState(false)
  const [show, setShow] = useState(false);
  const notificationAlertRef = React.useRef(null);

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getProducts = async () => {
      if (props.location.state !== null) {
        let state = props.location.state;
        let message = (<div><div>{`${state === "success" ? 'Success' : 'Error'}`}</div></div>)
        notify("tl", message, state)
      }
      setColour(document.body.classList.contains("white-content") ? "black" : "white");
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/admin/products', token).then(resp => {
        setProducts(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        setProducts([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getProducts()
  }, [])

  // const onButtonClick = async (contactId) => {
  //   const token = window.localStorage["Access_Token"]
  //   requestFileItem(`storage/download/zip?id=${contactId}`, token).then(response => {
  //     const filename = response.headers.get('Content-Disposition').split('filename=')[1];
  //     let url = window.URL.createObjectURL(new Blob([response.data]));
  //     let a = document.createElement('a');
  //     a.href = url;
  //     a.download = filename;
  //     a.click();
  //   });
  // }

  //on Search Data in Table 
  const onSearchData = (e) => {
    const searchText = e.target.value.toLowerCase();
  
    if (searchText !== "") {
      const filteredData = products.filter(
        (item) => {
          const lowercaseProductName = item.productName ? item.productName.toLowerCase() : '';
          return lowercaseProductName.includes(searchText);
        }
      );
  
      setFiltered(filteredData);
      setFilter(true);
    } else {
      setFiltered([]);
      setFilter(false);
    }
  };

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col md="12">
              <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
                <CardHeader>
                  <Row>
                    <Col md="10">
                      <CardTitle tag="h4">Products</CardTitle>
                      <p className="category">Products currently offered by Novas.</p>
                    </Col>
                    <Col><Link to={{
                      pathname: '/product-manager',
                      // search: '?',
                      // state: item
                    }}><Button style={{ color: `${textColour}` }}>
                        <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                      </Button></Link></Col></Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <Input
                      placeholder="Search by product name or product type..."
                      onChange={(e) => onSearchData(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className='card-plain'>
                <DataGrid
                   rows={filter ? filtered : products} 
                  sx={{
                    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                      color: theme === themes.dark ? 'white' : 'black',
                      fontWeight: 700,
                    },
                  }}
                  columns={[
                    { field: 'productName', headerName: 'Name', flex: 1 },
                    { field: 'description', headerName: 'Description', flex: 1 },
                    { field: 'visible', headerName: 'Visible', flex: 1 },
                    {
                      field: 'actions',
                      headerName: 'Action',
                      flex: 1,
                      renderCell: (params) => (
                        <div className="button-container">
                          <Link to={{
                            pathname: `/product/${params.row.productId}`,
                            state: params.row
                          }}>
                            <Button style={{ color: `${textColour}` }}>
                              <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                            </Button>
                          </Link>
                        </div>
                      ),
                    },
                  ]}
                  getRowId={(row) => row.productId}
                />
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default Products;