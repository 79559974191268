import React, { useState, useEffect, useContext } from 'react';
import NotificationAlert from "react-notification-alert";
import { getItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Blocks } from 'react-loader-spinner';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeContext, themes } from '../contexts/ThemeContext';

function Leads() {

    const { theme } = useContext(ThemeContext);

    const [IsLoaded, setIsLoaded] = useState(true);

    const notificationAlertRef = React.useRef(null);
    const notify = (place, message, type) => {


        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {

        const getMessages = async () => {
  
        }
        getMessages()

    }, [])

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            {IsLoaded ? (
                <div className='content'>
                   <h1>Coming soon...</h1>

                </div>
            ) : (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                    /></div>

            )
            }
        </>
    )

}

export default Leads;